<!--
 * @Description: 视频数据
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-22 20:08:34
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/data/screening/components/videoData.vue
-->
<template>
  <div class="app-wrapper" style="margin-top: 16px">
    <div class="wrapper-view">
      <div class="wrapper-title">视频数据</div>
      <div id="videoData"></div>
      <div class="screening-view">
        <div class="screening-item">
          <div class="screening-item-title">发布视频数</div>
          <div class="screening-item-text">
            <span>{{ counts?.videoItemCount.count || 0 }}</span
            >上个周期{{ counts?.videoItemCount.before_count || 0 }}（{{
              counts?.videoItemCount.increases || 0
            }}%）
          </div>
        </div>
        <div class="screening-item">
          <div class="screening-item-title">播放视频数</div>
          <div class="screening-item-text">
            <span>{{ counts?.videoPlayCount.count || 0 }}</span
            >上个周期{{ counts?.videoPlayCount.before_count || 0 }}（{{
              counts?.videoPlayCount.increases || 0
            }}%）
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let myChart = null;
export default {
  props: {
    charts: {
      type: Object,
    },
    counts: {
      type: Object,
    },
  },
  watch: {
    charts: {
      handler(val) {
        if (val) {
          this.initChart(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.initChart();
  },
  methods: {
    initChart({ videoItemChart, videoPlayChart }) {
      try {
        // 基于准备好的dom，初始化echarts实例
        myChart = echarts.init(document.getElementById("videoData"));
        // 绘制图表
        myChart.setOption &&
          myChart.setOption({
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              x: "right",
              y: "top",
            },
            grid: {
              left: "1%",
              right: "1%",
              bottom: "3%",
              top: "10%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: videoItemChart.columns,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "发布视频数",
                type: "bar",
                data: videoItemChart.values,
                barMaxWidth: 30,
              },
              {
                name: "播放视频数",
                type: "bar",
                data: videoPlayChart.values,
                barMaxWidth: 30,
              },
            ],
          });

        window.addEventListener("resize", () => {
          myChart.resize();
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#videoData {
  width: 100%;
  height: 320px;
}
.screening-view {
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-top: 10px;
  .screening-item {
    padding: 13px;
    color: #4e4e4e;
    flex: 1;
    border-right: 1px dashed #d5d7e0;
    &:last-child {
      border-right: 0;
    }
    &-title {
      font-size: 16px;
    }
    &-text {
      font-size: 14px;
      margin-top: 5px;
      span {
        font-size: 25px;
      }
    }
  }
}
</style>
