<!--
 * @Description: 账号线索互动
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-12-06 21:49:58
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/data/screening/mods/whole.vue
-->
<template>
  <el-dialog title="私信对话" :visible.sync="shows" width="600px">
    <el-table
      :data="tableData"
      stripe
      max-height="300"
      style="width: 100%; margin-top: 10px"
      v-loading="isLoading"
    >
      <el-table-column
        type="index"
        label="序号"
        width="55px"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="account_name"
        show-overflow-tooltip
        label="账号名称"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="total"
        show-overflow-tooltip
        label="线索互动数"
        align="center"
      >
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  ranking: "api/customer/userClueInteract/ranking",
};
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  created() {
    this.getRanking();
  },
  methods: {
    async getRanking() {
      const res = await HTTP({
        url: URL.ranking,
        method: "get",
      });
      this.tableData = res || [];
    },
  },
};
</script>
