<!--
 * @Description: 视频排名
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-09 20:41:45
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/data/ranking/index.vue
-->
<template>
    <div class="app-wrapper">
      <div class="wrapper-view">
        <div>
          <div class="wrapper-title">视频数据明细</div>
          <el-form
            :model="queryParams"
            ref="queryForm"
            size="small"
            :inline="true"
            style="margin-top: 20px"
          >
            <el-form-item label="发布时间:" prop="createTime">
              <el-date-picker
                align="center"
                style="width: 240px"
                v-model="queryParams.createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
  
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="getList(1)"
                >搜索</el-button
              >
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            :data="tableData"
            stripe
            max-height="580"
            style="width: 100%; margin-top: 21px"
            v-loading="isLoading"
          >
            <el-table-column 
              prop="account" 
              label="抖音号" 
              align="center">
            </el-table-column>
            
            <el-table-column 
              prop="title" 
              label="视频描述" 
              align="center">
            </el-table-column>

            <el-table-column
              prop="create_time"
              show-overflow-tooltip
              label="发布时间"
              align="center"
            >
            </el-table-column>

            <el-table-column 
              prop="todayPlayCount" 
              label="每日新增播放量" 
              align="center">
            </el-table-column>

            <el-table-column
              prop="play_count"
              show-overflow-tooltip
              label="累计播放量"
              align="center"
            >
            </el-table-column>

            <el-table-column prop="opts" label="操作" align="center" width="200">
              <template slot-scope="{ row }">
                <el-button
                  size="mini"
                  type="text"
                  @click="handleOpts('preview', row)"
                  >视频链接</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <pagination
            v-show="page.total > 0"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.size"
            @pagination="getList()"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { HTTP } from "../../../../../utils/request";
  // import moment from "moment";
  
  const URL = {
      list: "api/customer/userDashboard/videoDetailData",
      user_list: "api/customer/userAccount/tiktokAccounts",
    },
    QUERY = {
      createTime: [],
      keyword: null,
      ranking: null,
      tiktok_id: null,
    };
  
  // activeIndex = item.index
  
  export default {
    data() {
      return {
        queryParams: {
          ...QUERY,
        },
        tableData: [],
        // userList: [],
      };
    },
    mounted() {
      // this.getUserList();
      this.getList();
    },
    methods: {
      async getList(page = this.page.page, size = this.page.size) {
        this.isLoading = true;
        const [beginTime = null, endTime = null] =
          this.queryParams.createTime || [];
  
        const params = {
          ...this.queryParams,
          beginTime,
          endTime,
        };
        delete params.createTime;
        const res = await HTTP({
          url: URL.list,
          method: "get",
          data: {
            page,
            limit: size,
            ...params,
          },
        });
        let { total, per_page, current_page, data } = res || {};
        this.page = { total, page: current_page, size: +per_page };
        this.tableData = data || [];
        this.isLoading = false;
      },
      resetQuery() {
        this.queryParams = {
          ...QUERY,
        };
        this.getList(1);
      },
  
      // 账号列表
      async getUserList() {
        const res = await HTTP({
          url: URL.user_list,
          method: "get",
        });
        this.userList = res || [];
      },
  
      handleOpts(type, row) {
        switch (type) {
          case "preview":
            window.open(row.video_link);
            break;
          case "release":
            window.open(row.search_link);
            break;
          default:
            break;
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .wrapper-view {
    padding: 16px 26px;
    .wrapper-tip {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      line-height: 25px;
      color: #7c7f97;
    }
  }
  .head-tabs {
    display: flex;
    align-items: center;
    .tab-item {
      width: 76px;
      height: 32px;
      background: #f6f9ff;
      border: 1px solid #bec3cb;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #353535;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 12px;
      position: relative;
    }
    .active {
      border: 1px solid #4586f0;
      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 17px;
        display: inline-block;
        background: url("../../../../../assets/icon/active.png");
        background-size: cover;
        right: 0px;
        bottom: 0px;
      }
    }
  }
  </style>
  