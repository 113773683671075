<!--
 * @Description: 数据总览
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-19 19:40:08
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/data/screening/index.vue
-->
<template>
  <div>
    <div class="home-head">
      <div class="head-view-title">
        数据总览（账号总数：{{ statisticsInfo?.accountCount || 0 }}个）<span
          class="head-text"
          >以下数据为截止到昨天的数据汇总</span
        >
      </div>
      <div class="head-list">
        <div class="head-item app-wrapper">
          <div class="item-content">
            <div class="item-count">
              <div class="count-text">
                {{ statisticsInfo?.videoItemCount.count || 0 }}
              </div>
              <div
                class="count-span"
                v-if="statisticsInfo?.videoItemCount.increase >= 0"
              >
                <i class="el-icon-top"
                  >{{
                    statisticsInfo?.videoItemCount.increase | filterFixed
                  }}%</i
                >
              </div>
              <div
                class="count-span count-decline"
                v-if="statisticsInfo?.videoItemCount.increase < 0"
              >
                <i class="el-icon-bottom"
                  >{{
                    statisticsInfo?.videoItemCount.increase | filterFixed
                  }}%</i
                >
              </div>
            </div>
            <div class="item-name">视频总数</div>
          </div>
        </div>
        <div class="head-item app-wrapper">
          <div class="item-content">
            <div class="item-count">
              <div class="count-text">
                {{ statisticsInfo?.videoPlayCount.count || 0 }}
              </div>
              <div
                class="count-span"
                v-if="statisticsInfo?.videoPlayCount.increase >= 0"
              >
                <i class="el-icon-top"
                  >{{
                    statisticsInfo?.videoPlayCount.increase | filterFixed
                  }}%</i
                >
              </div>
              <div
                class="count-span count-decline"
                v-if="statisticsInfo?.videoPlayCount.increase < 0"
              >
                <i class="el-icon-bottom"
                  >{{
                    statisticsInfo?.videoPlayCount.increase | filterFixed
                  }}%</i
                >
              </div>
            </div>
            <div class="item-name">视频播放量</div>
          </div>
        </div>
        <div class="head-item app-wrapper">
          <div class="item-content">
            <div class="item-count">
              <div class="count-text">
                {{ statisticsInfo?.keywordsCount.count || 0 }}
              </div>
              <div
                class="count-span"
                v-if="statisticsInfo?.keywordsCount.increase >= 0"
              >
                <i class="el-icon-top"
                  >{{ statisticsInfo?.keywordsCount.increase || 0 }}%</i
                >
              </div>
              <div
                class="count-span count-decline"
                v-if="statisticsInfo?.keywordsCount.increase < 0"
              >
                <i class="el-icon-bottom"
                  >{{ statisticsInfo?.keywordsCount.increase || 0 }}%</i
                >
              </div>
            </div>
            <div class="item-name">关键词总数</div>
          </div>
        </div>
        <div class="head-item app-wrapper">
          <div class="item-content">
            <div class="item-count">
              <div class="count-text">
                {{ statisticsInfo?.clueInteractCount.count || 0 }}
              </div>
              <div
                class="count-span"
                v-if="statisticsInfo?.clueInteractCount.increase >= 0"
              >
                <i class="el-icon-top"
                  >{{ statisticsInfo?.clueInteractCount.increase || 0 }}%</i
                >
              </div>
              <div
                class="count-span count-decline"
                v-if="statisticsInfo?.clueInteractCount.increase < 0"
              >
                <i class="el-icon-bottom"
                  >{{ statisticsInfo?.clueInteractCount.increase || 0 }}%</i
                >
              </div>
            </div>
            <div class="item-name">线索互动数</div>
          </div>
        </div>
      </div>
      <div class="head-text">
        说明：如果您发现意向用户数量相对较低，并不代表没有效果。对于B2B销售，一些潜在客户更倾向于直接使用服务留资电话来联系。请留意并回应这些留资电话。
      </div>
    </div>

    <div class="head-view">
      <div class="head-view-title">数据趋势</div>
      <div class="head-view-time">
        <el-select
          v-model="days"
          size="mini"
          placeholder="请选择"
          @change="handleClick"
        >
          <el-option label="7天" :value="7"> </el-option>
          <el-option label="15天" :value="15"> </el-option>
          <el-option label="30天" :value="30"> </el-option>
        </el-select>
      </div>
    </div>

    <VideoDataView :charts="chartsInfo?.charts" :counts="chartsInfo?.counts" />
    <OnlineKeywordsView
      :keywordsChart="chartsInfo?.charts.keywordsChart"
      :keywordsCount="chartsInfo?.counts.keywordsCount"
    />

    <div class="screening-view">
      <div class="screening-item screening-left">
        <LeadInteractionView
          :clueInteractChart="chartsInfo?.charts.clueInteractChart"
          :clueInteractCount="chartsInfo?.counts.clueInteractCount"
        />
      </div>
      <div class="screening-item screening-right">
        <AccountInteractionView />
      </div>
    </div>

    <div class="screening-view" v-if='isShow'>
      <div class="screening-item screening-right">
        <VidoeDetailDataView />
      </div>
    </div>

    <!-- <div class="trend-view app-wrapper" v-loading="isLoading">
      <div class="wrapper-title" style="margin-bottom: 14px">数据趋势</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账号总数" name="1"></el-tab-pane>
        <el-tab-pane label="视频总量" name="2"></el-tab-pane>
        <el-tab-pane label="关键词总量" name="3"></el-tab-pane>
        <el-tab-pane label="视频播放量" name="4"></el-tab-pane>
      </el-tabs>
      <div class="trend">
        <div id="screening" style="width: 100%; height: 100%"></div>
      </div>
      <div class="screen-view">
        <el-select
          v-model="value"
          size="mini"
          placeholder="请选择"
          @change="handleClick"
        >
          <el-option label="7天" :value="7"> </el-option>
          <el-option label="15天" :value="15"> </el-option>
          <el-option label="30天" :value="30"> </el-option>
        </el-select>
      </div>
    </div> -->
  </div>
</template>

<script>
import { HTTP } from "../../../../utils/request";
import VideoDataView from "./components/videoData.vue";
import OnlineKeywordsView from "./components/onlineKeywords.vue";
import LeadInteractionView from "./components/leadInteraction.vue";
import AccountInteractionView from "./components/accountInteraction";
import VidoeDetailDataView from "./components/vidoeDetailData";

const URL = {
  statistics: "api/customer/userDashboard/statistics",
  charts: "api/customer/userDashboard/charts",
  showVideoDetailFlag: "api/customer/userDashboard/showVideoDetail",
};

export default {
  components: {
    VideoDataView,
    OnlineKeywordsView,
    LeadInteractionView,
    AccountInteractionView,
    VidoeDetailDataView,
  },
  data() {
    return {
      statisticsInfo: null,
      chartsInfo: null,
      days: 7,
      isShow: false
    };
  },
  async mounted() {
    this.getStatistics();
    this.getCharts();
    this.isShowVideoDetail();
  },
  filters: {
    filterFixed(val) {
      return val.toFixed(2);
    },
  },
  methods: {
    // 数据看板
    async getStatistics() {
      const res = await HTTP({
        url: URL.statistics,
        method: "get",
      });
      this.statisticsInfo = res;
    },

    // 图表
    async getCharts() {
      const res = await HTTP({
        url: URL.charts,
        method: "get",
        data: {
          days: this.days,
        },
      });
      this.chartsInfo = res;
    },

    // 图表
    async isShowVideoDetail() {
      const res = await HTTP({
        url: URL.showVideoDetailFlag,
        method: "get",
      });
      this.isShow = res.flag;
    },

    // 切换数据
    handleClick() {
      this.getCharts();
    },
  },
};
</script>

<style scoped lang="scss">
.home-head {
  padding: 16px 0;
  .head-list {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .head-item {
      width: 225px;
      height: 135px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      .item-content {
        .item-count {
          display: flex;
          .count-text {
            font-size: 28px;
            font-family: OPPOSans-B-, OPPOSans-B;
            font-weight: bold;
            color: #4e6bef;
          }
          .count-span {
            font-size: 12px;
            font-family: OPPOSans-B-, OPPOSans-B;
            font-weight: bold;
            color: #67c23a;
          }
          .count-decline {
            color: #f56c6c;
          }
        }
        .item-name {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;
          color: #4e4e4e;
          margin-top: 4px;
        }
      }
      .item-x {
        width: 1px;
        height: 67px;
        background: #d5d7e0;
        margin-right: 35px;
      }
    }
  }
  .head-text {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
    font-weight: normal;
    color: #7c7f97;
    margin-top: 10px;
  }
}
.head-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  &-title {
    font-size: 25px;
    font-weight: bold;
  }
  &-time {
    width: 100px;
  }
}
.screening-view {
  display: flex;
  margin-top: 16px;
  .screening-item {
    flex: 1;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.trend-view {
  padding: 16px 26px;
  margin-top: 17px;
  position: relative;
  height: calc(100vh - 275px);
  overflow: hidden;
  .trend {
    width: 100%;
    height: calc(100% - 100px);
  }
  .screen-view {
    position: absolute;
    top: 55px;
    right: 30px;
    width: 100px;
    height: 30px;
  }
}
</style>
