<!--
 * @Description: 账号线索互动TOP6
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-22 21:14:36
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/data/screening/components/AccountInteraction.vue
-->
<template>
  <div class="app-wrapper" style="height: 401px">
    <div class="wrapper-view">
      <div class="account-head">
        <div class="wrapper-title">账号线索互动TOP6</div>
        <div
          class="account-whole"
          v-if="list.length > 6"
          @click="whole.show = true"
        >
          全部<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="account-list">
        <template v-for="(item, index) in list">
          <div class="account-item" v-if="index < 6" :key="item.to_user_id">
            <div class="account-item-top">
              <div class="top-text">线索互动数</div>
              <div class="top-count">{{ item.total }}</div>
            </div>
            <div class="account-item-text">{{ item.account_name }}</div>
          </div>
        </template>
      </div>
    </div>

    <WholeView v-if="whole.show" :show.sync="whole.show" />
  </div>
</template>

<script>
import WholeView from "../mods/whole.vue";
import { HTTP } from "../../../../../utils/request";

const URL = {
  ranking: "api/customer/userClueInteract/ranking",
};
export default {
  components: {
    WholeView,
  },
  data() {
    return {
      list: [],
      whole: {
        show: false,
      },
    };
  },
  mounted() {
    this.getRanking();
  },
  methods: {
    async getRanking() {
      const res = await HTTP({
        url: URL.ranking,
        method: "get",
      });
      this.list = res || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.account-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .account-whole {
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
}
.account-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;

  .account-item {
    width: calc(50% - 8px);
    background-color: #f7f8fa;
    padding: 10px;
    border-radius: 8px;
    margin-top: 16px;
    margin-right: 16px;
    &:nth-child(even) {
      margin-right: 0;
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .top-text {
        font-size: 16px;
      }
      .top-count {
        font-size: 25px;
      }
    }
    &-text {
      font-size: 14px;
      border-top: 1px dashed #d5d7e0;
      padding-top: 10px;
    }
  }
}
</style>
