<!--
 * @Description: 销售线索互动
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-22 21:10:33
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/data/screening/components/leadInteraction.vue
-->
<template>
  <div class="app-wrapper">
    <div class="wrapper-view">
      <div class="wrapper-title">销售线索互动</div>
      <div id="leadInteraction"></div>
      <div class="screening-view">
        <div class="screening-item">
          <div class="screening-item-title">销售线索</div>
          <div class="screening-item-text">
            <span>{{ clueInteractCount?.count || 0 }}</span
            >上个周期{{ clueInteractCount?.before_count || 0 }}（{{
              clueInteractCount?.increases || 0
            }}%）
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let myChart = null;
export default {
  props: {
    clueInteractChart: {
      type: Object,
    },
    clueInteractCount: {
      type: Object,
    },
  },
  watch: {
    clueInteractChart: {
      handler(val) {
        if (val) {
          this.initChart(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.initChart();
  },
  methods: {
    initChart({ columns, values }) {
      try {
        // 基于准备好的dom，初始化echarts实例
        myChart = echarts.init(document.getElementById("leadInteraction"));
        // 绘制图表
        myChart.setOption &&
          myChart.setOption({
            color: ["#04c0dd"],
            tooltip: {
              trigger: "axis",
            },
            legend: {
              x: "right",
              y: "top",
            },
            grid: {
              left: "1%",
              right: "1%",
              bottom: "3%",
              top: "10%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: columns,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "销售线索",
                type: "line",
                smooth: true,
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "rgba(4, 192, 221, 0.2)",
                        },
                        {
                          offset: 1,
                          color: "rgba(4, 192, 221, 0.05)",
                        },
                      ],
                      false
                    ),
                    shadowColor: "rgba(4, 192, 221, 0.2)",
                    shadowBlur: 10,
                  },
                },
                data: values,
              },
            ],
          });

        window.addEventListener("resize", () => {
          myChart.resize();
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#leadInteraction {
  width: 100%;
  height: 250px;
}
.screening-view {
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-top: 10px;
  .screening-item {
    padding: 13px;
    color: #4e4e4e;
    flex: 1;
    border-right: 1px dashed #d5d7e0;
    &:last-child {
      border-right: 0;
    }
    &-title {
      font-size: 16px;
    }
    &-text {
      font-size: 14px;
      margin-top: 5px;
      span {
        font-size: 25px;
      }
    }
  }
}
</style>
